import React,{ useState,useRef } from 'react'
import Style from './CreateMission.module.scss'
import CreatableSelect from 'react-select';
import { durationItems, place, technicalSkillsItems } from '../../Utility/options'
import Geosuggest, { Suggest } from 'react-geosuggest';
import './RegisterForm2Client.css'
import useForm from './ValidateForm/useForm'
import { hidden } from '../../Utility/request';
import Validate from './ValidateForm/Validate'
import { useHistory } from 'react-router';


const CreateMission = () => {


   
    const [budget, setBudget] = useState<boolean>(false)
    const geosuggestEl = useRef(null);
    const [show, setShow] = useState(false)
    const [message, setMessage] = useState(false)

    const history = useHistory()


    const submit =  async () => {

        let accessToken = localStorage.getItem('currentUser')
        let config = 
        {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            }
        }
        

        let data =  hidden(valuesSecondStepClient, config)
        data.then(res => {
            setMessage(true)
        })
        .catch(err => {
            console.log(err)
        })
    }

    const setTechnicalSkills = (techni:any) => {
        setValuesSecondStepClient({
            ...valuesSecondStepClient,
            technicalSkills: techni
        })
    }
    const setDuration = (duration: any) => {
        setValuesSecondStepClient({
            ...valuesSecondStepClient,
            duration: duration
        })
    }
    const onSuggestSelect = (suggest: Suggest) => {
        if(suggest) {
            setValuesSecondStepClient({
                ...valuesSecondStepClient,
                localisation: {
                    label: suggest.label,
                    location: {
                        lat: suggest.location.lat,
                        lng: suggest.location.lng
                    }
                }
                
            })
        }
    };

    const setPlace = (places:any) => {
        setValuesSecondStepClient({
            ...valuesSecondStepClient,
            place: places
        })
    }

    const setBillingModeForfait= () => {
        setValuesSecondStepClient({
            ...valuesSecondStepClient,
            billingMode: "Forfait"
        })
    }

    const setBillingModeRegie= () => {
        setValuesSecondStepClient({
            ...valuesSecondStepClient,
            billingMode: "Regie"
        })
    }

    const redirect = () => {
        history.push('/')
    }

    const { handleChangeSecondStepClient, errors, handleSubmit, valuesSecondStepClient, setValuesSecondStepClient} = useForm(submit, Validate)

    
    return (
        <div className={Style.container}>
            <div className={!message ? Style.containAll : Style.hidden}>
                <h1 className={Style.title}>Ma définition du projet</h1>
                <div className={Style.barre}></div>
                <form onSubmit={handleSubmit}>
                    <div className={Style.inputTitre}>
                        <label>Titre</label>
                        <input type="text" name="title" onChange={handleChangeSecondStepClient}/>
                    </div>
                    {/* {errors.title && <p className={Style.errors}>{errors.title}</p>} */}
                    <div className={Style.inputDescription}>
                        <label>Description</label>
                        <textarea name="description" id="" cols={10} rows={5}  onChange={handleChangeSecondStepClient}></textarea>
                    </div>
                    {/* {errors.description && <p className={Style.errors}>{errors.description}</p>} */}
                    <div className={Style.inputDescription}>
                            <label>Quelles compétences je recherche ?</label>
                            {/* <textarea name="searchSkills" id="" cols={10} rows={5} onChange={handleChangeSecondStepClient}></textarea> */}
                            <CreatableSelect 
                                options={technicalSkillsItems}
                                isMulti={true}
                                onChange={setTechnicalSkills}
                            />
                        </div>
                    {/* {errors.searchSkills && <p className={Style.errors}>{errors.searchSkills}</p>} */}
                    <div className={Style.inputDescription}>
                            <label>Zone Géographique</label>
                            {/* <input type="text" placeholder="Ex : Paris" name="geographicalZone" onChange={handleChangeSecondStepClient}/> */}
                                <Geosuggest
                                    ref={geosuggestEl}
                                    placeholder="Start typing!"
                                    location={new google.maps.LatLng(53.558572, 9.9278215)}
                                    radius={20}
                                    onSuggestSelect={onSuggestSelect}
                                    autoComplete="off"
                                    country="Fr"
                                />
                                
                        </div>
                        <div className={Style.inputDescription}>
                            <label>Lieu de la mission</label>
                            <div>
                                {/* {
                                    placeOfMission.map((places, i) => (
                                        <button key={i} className={index === i ? places.classBlue : places.class} value={places.place} onClick={e => {setIndex(i); getPlaceOfMission(e)}} name="placeOfMission">{places.place}</button> 
                                    ))
                                } */}
                                <CreatableSelect 
                                    options={place}
                                    isMulti={true}
                                    onChange={setPlace}
                                />
                            </div>
                        </div>
                    {/* {errors.placeOfMission && <p className={Style.errors}>{errors.placeOfMission}</p>} */}
                    <div className={Style.inputDescription}>
                            <label>Durée du projet </label>
                            {/* <input name="projectDuration" type="text" placeholder="Ex : 6 mois" onChange={handleChangeSecondStepClient}/> */}
                            <CreatableSelect 
                                options={durationItems}
                                isMulti={true}
                                onChange={setDuration}
                            />
                        </div>
                    {/* {errors.projectDuration && <p className={Style.errors}>{errors.projectDuration}</p>} */}
                    {/* <div className={Style.inputStartProjet}>
                        <label>Depart du projet</label>
                        <div>
                            {
                                startOfProject.map((start, i) => (
                                    <button key={i} className={indexProject === i ? start.classBlue : start.class} value={start.date} onClick={e => {setIndexProject(i);}} name="StartOfProject">{start.date}</button>
                                ))
                            }
                        
                        </div>
                    </div> */}
                    <h6 className={Style.question}>Sur quel mode de facturation je vais fonctionner?</h6>
                    <div className={Style.facturation}>
                        <div className={!show ? Style.forfait : Style.regie}>
                            <button className={Style.buttonForfait} onClick={e => { e.preventDefault(); setShow(false); setBillingModeForfait()}}>Forfait</button>
                        </div>
                        <div className={show ? Style.forfait : Style.regie}>
                            <button className={Style.buttonForfait} onClick={e => { e.preventDefault(); setShow(true); setBillingModeRegie()}}>Régie</button>
                        </div>
                    </div>
                    {
                        !show ?

                            !budget
                                ?
                                    <div className={Style.answer}>
                                        <p>Je peux évaluer mon budget TTC </p>
                                        <button className={Style.yes} onClick={e => {setBudget(true)}} >Oui</button>
                                        <button className={Style.notYet}>Pas encore</button>
                                    </div>
                                :
                                    <div className={Style.answer}>
                                        <p>Mon budget TTC est de</p>
                                        <input type="number" name="budgetMin" onChange={handleChangeSecondStepClient} />
                                        <div>
                                            <label htmlFor="mois">Mois</label>
                                            <select name="" id="mois"></select>
                                        </div>
                                    </div>
                            :
                                !budget
                                    ?
                                        <div className={Style.answer}>
                                            <p>Je peux évaluer mon taux horaire journalier </p>
                                            <button className={Style.yes} onClick={e => {setBudget(true)}} >Oui</button>
                                            <button className={Style.notYet}>Pas encore</button>
                                        </div>
                                :

                                    <div className={Style.answer}>
                                        <p>j'évalue en TTC mon budget TJM à :</p>
                                        <input type="number" name="budgetMin" onChange={handleChangeSecondStepClient} />
                                    </div>
                    }
                    {/* {errors.StartOfProject && <p className={Style.errors}>{errors.StartOfProject}</p>} */}
                    <button className={Style.button}>Continuer</button>
                </form>
            </div>
            <div className={message ? Style.message : Style.hidden}>
                <h1>Merci pour votre proposition ! Nous sourçons les candidats. Vous serez notifié une fois que la shortlist sera constituée !</h1>
                <div className={Style.barre}></div>
                <button onClick={redirect}>Retour au dahsboard</button>
            </div>        
        </div>
    )
}

export default CreateMission
