import React from 'react'
import Style from './MyPresentation.module.scss'
// import pencil from '../../../assets/pencil.svg'

const MyPresentation = ({user}:any) => {
    return (
        <div className={Style.container}>
            <div className={Style.containDetails}>
                <h1 className={Style.title}>Ma présentation</h1>
                <div className={Style.barre}></div>
                {/* <div className={Style.containImg}>
                    <img src={pencil} alt="un stylo en guise de bouton"/>
                </div> */}
                <p>{user.profile.description}</p>
            </div>            
        </div>
    )
}

export default MyPresentation
