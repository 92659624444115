import moment from 'moment'
import React from 'react'
import Style from './MyDegree.module.scss'

const MyDegree = ({user}:any) => {
    return (
        <div className={Style.container}>
            <div className={Style.containDetails}>
                <div className={Style.containImg}>
                    <h5 className={Style.title}>Diplômes</h5>
                    <div className={Style.barre}></div>
                </div>
                {
                    user.profile.education.map((res:any, i:number) => (
                        <div className={Style.nameDegree} key={i}>
                            <p className={Style.weigth}>{res.title}</p>
                            <p>{res.school}</p>
                            <p>{moment(res.startDate).format('MM-YYYY')} - {moment(res.endDate).format('MM-YYYY')}</p>
                        </div>  
                    ))
                }
                <div className={Style.containImg}>
                    <h5 className={Style.title}>Certifications</h5>
                    <div className={Style.barre}></div>
                </div>

                {
                    user.profile.certification.map((res:any, i:number) => (
                        <div className={Style.nameDegree} key={i}>
                            <p className={Style.weigth}>{res.title}</p>
                            <p>{moment(res.obtainDate).format('MM-YYYY')}</p>
                            
                        </div>  
                    ))
                }
            </div>
        </div>
    )
}

export default MyDegree
