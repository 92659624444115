import React, { useCallback } from 'react'
import { useHistory } from 'react-router'
import { assign } from '../../Utility/request'
import Style from './Profil.module.scss'


const Profil = ({result, project, setProfil, id, isSearch}:any) => {
    
    const history = useHistory()
    const redirectProject = (data:any) => {
        history.push({
            pathname: '/mission',
            state: { project: data}
        })
    }


    const getMissions =  useCallback( async (userId:string) => {
        const user = {
            "consultantId": userId
        }  
        const data = await assign(id, user)
        if(data) {
            history.push({
                pathname: '/dashboard',
                state: {
                    modal: true
                }
            })
        }
    }, [id, history])


    const handleShow = () => {
        if(result) {
            return (
                result.map((res:any, i:number) => (
                    
                    <div className={ Style.container} key={i}>
                        <div className={Style.containAll}>
                            <div className={Style.details}>
                                <div className={Style.detailConsultant}>
                                    <button className={Style.button} onClick={e => {setProfil({boolean: true, user: result[i]})}}>{res.firstname} {res.lastname} {" "}<span>voir profil</span></button>
                                    <button className={ !isSearch ? Style.assign : Style.hidden} onClick={() => {getMissions(res._id)}}>Assigner</button>
                                </div>
                            </div>
                        </div>
                    </div>
                ))
            )
        }
        else if (project) {
            return (
                project.map((res:any, i:number) => (
                
                    <div className={ Style.container} key={i}>
                        <div className={Style.containAll}>
                            <div className={Style.details}>
                                <div className={Style.detailConsultant}>
                                    <button className={Style.button} onClick={e => {redirectProject(project[i])}}>{res.title} {" "}<span>voir Mission</span></button>
                                </div>
                            </div>
                        </div>
                    </div>
                ))
            )
        }
        else {
            return (
                <h1 className={Style.red}>Aucun Résultalt</h1>
            )
        }
    }

    return (
        <div className={Style.all}>
            {
                handleShow()
            }    
        </div>
    )
}

export default Profil
