import React from 'react'
import Style from './MyCompetences.module.scss'

const MyCompetences = ({user}:any) => {
    return (
        <div className={Style.container}>
            <div className={Style.containDetails}>
                <h1 className={Style.title}>Compétences</h1>
                <div className={Style.barre}></div>
                <div className={Style.skills}>
                    {
                        user.profile.functionalSkills.map((res:any, i:number) => (
                            <span key={i}>{"#" + res.value + " "} </span>
                        ))
                    }
                    {
                        user.profile.technicalSkills.map((res:any, i:number) => (
                            <span key={i}>{"#" + res.value + "   "} </span>
                        ))
                    }
                </div>
            </div>            
        </div>
    )   
}

export default MyCompetences
