import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import {isAdmin} from '../Utility/untils'

const AdminRoutes = ({component: Component, ...rest}:any) => {

    return (
        <Route {...rest} render={props => (
            isAdmin() ?
                <Component {...props} />
            : <Redirect to="/" />
        )} />
    );
};

export default AdminRoutes;