import React, { useState } from "react";
import Style from "./Connexion.module.scss";
import { logintest } from "../../../Utility/request";
import { useHistory } from "react-router";

const Connexion = () => {
    const [values, setValues] = useState<object>({
        email: "",
        password: "",
    });
    const history = useHistory()

    const handleChange = (e: any) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value,
        });
    };

    const submit = async () => {
        await logintest(values)
            .then((res:any) => {
                localStorage.setItem('status', "odvlknsqmvnsqvnmb")
                localStorage.setItem('currentUser', res.accessToken)
                if(res.user.roles[0] === "admin") {
                    history.push('/dashboard')
                }
                window.location.reload()
            })
            .catch((err:any) => {
                console.log(err);
            });
    };

    return (
        <div className={Style.section}>
            <div className={Style.container}>
                <h1 className={Style.title}>Connexion</h1>
                <div className={Style.barre}></div>
                <input
                    type="email"
                    placeholder="E-mail professionnel"
                    className={Style.input}
                    name="email"
                    onChange={handleChange}
                ></input>
                {/* {errors.email && <p className={Style.errors}>{errors.email}</p>} */}
                <input
                    type="password"
                    placeholder="Mot de passe"
                    className={Style.input}
                    name="password"
                    onChange={handleChange}
                ></input>
                {/* {errors.password && <p className={Style.errors}>{errors.password}</p>} */}
                <button className={Style.send} onClick={submit}>
                    Connexion
                </button>
            </div>
        </div>
    );
};

export default Connexion;
