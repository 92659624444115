import React from 'react'
import Connexion from '../../../Components/Auth/Connexion/Connexion'
import Style from './Auth.module.scss'

const Auth = () => {
    return (
        <div className={Style.container}>
            <Connexion />
        </div>
    )
}

export default Auth
