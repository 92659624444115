import React, { useCallback } from 'react'
import Style from './MyProfil.module.scss'
import Profil from '../../Components/MyProfil/Available/Available'
import Completed from '../../Components/MyProfil/CompletedProfil/CompletedProfil'
import Preference from '../../Components/MyProfil/MyPreferences/MyPreferences'
import Degree from '../../Components/MyProfil/MyDegree/MyDegree'
import Skills from '../../Components/MyProfil/MyCompetences/MyCompetences'
import Presentation from '../../Components/MyProfil/MyPresentation/MyPresentation'
import Experience from '../../Components/MyProfil/Experiences/Experiences'
import Opinion from '../../Components/MyProfil/Opinion/Opinion'
import { useHistory } from 'react-router'
import {HiArrowNarrowLeft} from 'react-icons/hi'
import { assign } from '../../Utility/request'

const MyProfil = ({data, setProfil, id, isSearch}: any) => {

    // const location = useLocation()
    // const currentUser:any = location.state
    // let user;
    // if(currentUser) {
    //      user = currentUser.user
    // }

    // const history = useHistory()
    const user = data
    const history = useHistory()

    const getMissions =  useCallback( async (userId:string) => {
        const user = {
            "consultantId": userId
        }  
        const data = await assign(id, user)
        if(data) {
            history.push('/')
        }
    }, [id, history])


    return (
        <div className={Style.container}>
            <div className={Style.button}>
                <button className={Style.button1} onClick={() => setProfil(false)}>Back <HiArrowNarrowLeft/></button>
                <button className={!isSearch ? Style.button2 : Style.hidden} onClick={() => getMissions(user._id)}>Assigner</button>
            </div>
            {
                user
                    ?
                        <div className={Style.containAll}>
                            <div className={Style.colLeft}>
                                <Profil user={user}/>
                                <Completed />
                                <Preference user={user} />
                            </div>
                            <div className={Style.colRight}>
                                <Skills user={user}/>
                                <Presentation user={user} />
                                <Experience user={user}/>
                                <Degree user={user}/>
                                <Opinion />
                            </div>
                        </div>
                    :
                        null
            }
            
        </div>
    )
}

export default MyProfil
