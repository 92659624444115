export const colors = {
    danger: '#ea6845',
    dark: '#4d4d4d',
    info: '#2e7cff',
    light: '#c5eaff',
    primary: '#1b5ea6',
    red: '#ea6845',
    secondary: '#34c8ed',
    success: '#8edf53',
    warning: '#ffb017',
    white: '#fff',
  };
  
  export const currency = {
    EUR: '€',
  };
  
  // export const appliedRate = 10;
  
  
  export const technicalSkillsItems = [
    { value: "Cloud", label: "Cloud" },
    { value: "User interface design", label: "User interface design" },
    { value: "Data mining", label: "Data mining" },
    { value: "Perl/Python/Ruby", label: "Perl/Python/Ruby" },
    { value: "Developpement mobile", label: "Developpement mobile" },
    { value: "C/C++", label: "C/C++" },
    { value: "J2EE", label: "J2EE" },
    { value: ".Net", label: ".Net" },
    { value: "Big Data", label: "Big Data" },
    { value: "SEO", label: "SEO" },
    { value: "Unix", label: "Unix" },
    { value: "Linux", label: "Linux" },
    { value: "C#", label: "C#" },
    { value: "Citrix", label: "Citrix" },
    { value: "PHP", label: "PHP" },
    { value: "Java", label: "Java" },
    { value: "Ruby", label: "Ruby" },
    { value: "Javascript", label: "Javascript" },
    { value: "Angular", label: "Angular" },
    { value: "React", label: "React" },
    { value: "Jquery", label: "Jquery" },
    { value: "SQL", label: "SQL" },
    { value: "HTML", label: "HTML" },
    { value: "CSS", label: "CSS" },
    { value: "Oracle", label: "Oracle" },
    { value: "Symfony", label: "Symfony" },
    { value: "Wordpress", label: "Wordpress" },
    { value: "MongoDB", label: "MongoDB" },
    { value: "Base de données", label: "Base de données" },
    { value: "Oracle", label: "Oracle" },
    { value: "Datawharehouse", label: "Datawharehouse" },
    { value: "Décisionnel", label: "Décisionnel" },
    { value: "Datastage", label: "Datastage" },
    { value: "Redis", label: "Redis" },
    { value: "Sql server", label: "Sql server" },
    { value: "Informatica", label: "Informatica" },
    { value: "Postgresql", label: "Postgresql" },
    { value: "Mysql", label: "Mysql" },
    { value: "Sybase", label: "Sybase" },
    { value: "Data quality", label: "Data quality" },
    { value: "Hyperion", label: "Hyperion" },
    { value: "Github", label: "Github" },
    { value: "Nosql", label: "Nosql" },
    { value: "Plsql", label: "Plsql" },
    { value: "Hadoop", label: "Hadoop" },
    { value: "Db2", label: "Db2" },
    { value: "Mainframe", label: "Mainframe" },
    { value: "Blockchain", label: "Blockchain" },
    { value: "Business objects", label: "Business objects" },
    { value: "Django", label: "Django" },
    { value: "Qlikview", label: "Qlikview" },
    { value: "Tableau", label: "Tableau" },
  
  ]
  
  
  
  
  
  export const functionalSkillsItems = [
    { value: "Agilité", label: "Agilité" },
    { value: "AMOA - Assistance à maîtrise d'ouvrage", label: "AMOA - Assistance à maîtrise d'ouvrage" },
    { value: "Analyse statistique", label: "Analyse statistique" },
    { value: "Architecture", label: "Architecture" },
    { value: "Audit interne", label: "Audit interne" },
    { value: "Benchmarking et analyse concurrentielle", label: "Benchmarking et analyse concurrentielle" },
    { value: "Big data", label: "Big data" },
    { value: "Business plan", label: "Business plan" },
    { value: "Cadrage projet", label: "Cadrage projet" },
    { value: "Comptabilité", label: "Comptabilité" },
    { value: "Conception de campagnes communication", label: "Conception de campagnes communication" },
    { value: "Conception de stratégie marketing", label: "Conception de stratégie marketing" },
    { value: "Conduite du changement", label: "Conduite du changement" },
    { value: "Conformité", label: "Conformité" },
    { value: "Développement durable", label: "Développement durable" },
    { value: "Due diligence", label: "Due diligence" },
    { value: "E-commerce & marketing digital", label: "E-commerce & marketing digital" },
    { value: "Finance d'entreprise", label: "Finance d'entreprise" },
    { value: "Gestion budgétaire et financière", label: "Gestion budgétaire et financière" },
    { value: "Gestion de la culture d'entreprise", label: "Gestion de la culture d'entreprise" },
    { value: "Gestion de portefeuille", label: "Gestion de portefeuille" },
    { value: "Gestion de projet", label: "Gestion de projet" },
    { value: "Gestion de projet informatique", label: "Gestion de projet informatique" },
    { value: "Gestion des processus", label: "Gestion des processus" },
    { value: "Lean management", label: "Lean management" },
    { value: "Management de l'évolution réglementaire", label: "Management de l'évolution réglementaire" },
    { value: "Management de l'innovation", label: "Management de l'innovation" },
    { value: "Management de la qualité", label: "Management de la qualité" },
    { value: "Management du risque", label: "Management du risque" },
    { value: "Optimisation de la production", label: "Optimisation de la production"},
    { value: "Optimisation des systèmes", label: "Optimisation des systèmes" },
    { value: "PMO - project management office", label: "PMO - project management office" },
    { value: "Princing", label: "Princing" },
    { value: "Restruction", label: "Restruction" },
    { value: "Veille, analyse et gestion documentaire", label: "Veille, analyse et gestion documentaire" },
    { value: "Vente et gestion de comptes clients", label: "Vente et gestion de comptes clients" },
    { value: "Stratégie et Business plan", label: "Stratégie et Business plan" },
    { value: "Analyse sectorielle", label: "Analyse sectorielle" },
    { value: "Etude d'opportunité et de faisabilité", label: "Etude d'opportunité et de faisabilité" },
    { value: "Aide au choix et cadrage", label: "Aide au choix et cadrage" },
    { value: "Structure organisationnelle", label: "Structure organisationnelle" },
    { value: "Optimisation de la performance", label: "Optimisation de la performance" },
    { value: "Pilotage de projet/programme", label: "Pilotage de projet/programme" },
    { value: "Gouvernance SI", label: "Gouvernance SI" },
    { value: "Urbanisation et schéma directeur", label: "Urbanisation et schéma directeur" },
    { value: "MOA - Maîtrise d'ouvrage", label: "MOA - Maîtrise d'ouvrage" },
    { value: "Recette", label: "Recette" },
    { value: "Formation", label: "Formation" },
    { value: "Stratégie digitale", label: "Stratégie digitale" },
    { value: "Digitalisation des processus", label: "Digitalisation des processus" },
    { value: "pilotage de la transformation", label: "pilotage de la transformation" },
    { value: "Data marketing", label: "Data marketing" },
    { value: "Mobilité et Cloud", label: "Mobilité et Cloud" },
    { value: "Coaching", label: "Coaching" },
    { value: "Conseil en communication", label: "Conseil en communication" },
    { value: "Audit des organisations", label: "Audit des organisations" },
    { value: "Transformation RH", label: "Transformation RH" },
    { value: "Refonte des organsations", label: "Refonte des organsations" },
    { value: "Recrutement", label: "Recrutement" },
    { value: "RSE", label: "RSE" },
  
    ]
  
  
  export const subtypeItems = [
    { value : 'Stratégie et Business Plan'											 , label :	'Stratégie et Business Plan'                       },  
    { value : 'Etudes sectorielles et analyse de cible'	         , label :	'Etudes sectorielles et analyse de cible'          },  
    { value : 'Audit / Etudes d’opportunité et de faisabilité'   , label :	'Audit / Etudes d’opportunité et de faisabilité'   },  
    { value : 'Aide au choix / Cadrage'                          , label :	'Aide au choix / Cadrage'                          },  
    { value : 'Conduite du changement'                           , label :	'Conduite du changement'                           },  
    { value : 'Structure organisationnelle'                      , label :	'Structure organisationnelle'                      },  
    { value : 'Optimisation de la performance'                   , label :	'Optimisation de la performance'                   },  
    { value : 'Gestion des processus'                            , label :	'Gestion des processus'                            },  
    { value : 'Pilotage de programme ou projet et PMO'           , label :	'Pilotage de programme ou projet et PMO'           },  
    { value : 'Gouvernance SI et cadrage'                        , label :	'Gouvernance SI et cadrage'                        },  
    { value : 'Urbanisation et schéma directeur'                 , label :	'Urbanisation et schéma directeur'                 },  
    { value : 'Maîtrise d’ouvrage MOA et AMOA'                   , label :	'Maîtrise d’ouvrage MOA et AMOA'                   },  
    { value : 'Conduite du changement'                           , label :	'Conduite du changement'                           },  
    { value : 'Recette et formation'                             , label :	'Recette et formation'                             },  
    { value : 'Stratégie Digitale'                               , label :	'Stratégie Digitale'                               },  
    { value : 'Digitalisation des processus'                     , label :	'Digitalisation des processus'                     },  
    { value : 'Cadrage et pilotage de la transformation'         , label :	'Cadrage et pilotage de la transformation'         },  
    { value : 'Big Data et Data Marketing'                       , label :	'Big Data et Data Marketing'                       },  
    { value : 'Mobilité et Cloud'                                , label :	'Mobilité et Cloud'                                },  
    { value : 'Coaching individuel et équipes'                   , label :	'Coaching individuel et équipes'                   },  
    { value : 'Conseil en communication'                         , label :	'Conseil en communication'                         },  
    { value : 'Accompagnement professionel'                      , label :	'Accompagnement professionel'                      },  
    { value : 'Audit des organisations'                          , label :	'Audit des organisations'                          },  
    { value : 'Transformation RH et refonte des réorganisations' , label :	'Transformation RH et refonte des réorganisations' },  
    { value : 'Digitalisation du Recrutement'                    , label :	'Digitalisation du Recrutement'                    },  
    { value : 'la mobilité et carrière'                          , label :	'la mobilité et carrière'                          },  
    { value : 'RSE'                                              , label :	'RSE'                                              },  
    { value : 'social media & community management'              , label :	'social media & community management'              }   
  ]
  
  
  export const placeItems = [
    { value: 'Régie', label: 'Régie' },
    { value: 'Forfait', label:  'Forfait' }
  ]

  export const place =  [
    { value: 'Teletravail', label: 'Teletravail' },
    { value: 'Dans nos locaux', label:  'Dans nos locaux' },
    { value: 'Les deux', label: 'Les deux' },
  ]
  
  export const deliverableItems = [
    { value: 'Note de cadrage', label: 'Note de cadrage' },
    { value: 'Budget', label: 'Budget' },
    { value: 'Planning', label: 'Planning' },
    { value: "RFP (appel d'offres)", label: "RFP (appel d'offres)" },
    { value: 'Comptes rendu', label: 'Comptes rendu' },
    { value: 'Spécifications fonctionnelles', label: 'Spécifications fonctionnelles' },
    { value: 'Spécifications techniques', label: 'Spécifications techniques' },
    { value: 'Plan de test', label: 'Plan de test' },
    { value: 'Stratégie de test', label: 'Stratégie de test' },
    { value: 'User acceptance tests', label: 'User acceptance tests' },
    { value: 'Cahier de charge', label: 'Cahier de charge' },
    { value: 'Etude de faisabilité', label: 'Etude de faisabilité' },
    { value: 'Etude de risques', label: 'Etude de risques' },
    { value: 'Plan de communication', label: 'Plan de communication' },
    { value: "Plan d'assurance qualité", label: "Plan d'assurance qualité" },
    { value: 'Maquette fonctionnel', label: 'Maquette fonctionnel' },
    { value: "Rapport d'audit", label: "Rapport d'audit" },
    { value: 'Plan de formation', label: 'Plan de formation' },
    { value: 'Pilote (POC)', label: 'Pilote (POC)' },
    { value: 'Architecture', label: 'Architecture' },
    { value: 'Plan de certification', label: 'Plan de certification' },
    { value: 'Plan de transition', label: 'Plan de transition' },
  ]
  
  
  export const durationItems = [
   // { value: 'Immédiatement', label: 'Immédiatement' },
    { value: 'Moins de 2 semaines', label:  'Moins de 2 semaines' },
    { value: "Moins d'un  mois", label: "Moins d'un  mois"},
    { value: 'Entre 1 et 3 mois' , label:  'Entre 1 et 3 mois' },
    { value: 'Plus de 3 mois', label:  'Plus de 3 mois' }
  ]
  
  
  export const sectorItems = [
    { value: 'Secteur bancaire', label: 'Secteur bancaire' },
    { value: 'Services financiers', label:  'Services financiers' },
    { value: 'Aéronautique', label:  'Aéronautique' },
    { value: 'Logiciels et services informatiques', label:  'Logiciels et services informatiques' },
    { value: 'Transport et logistique', label:  'Transport et logistique' },
    { value: 'Secteur énergétique', label:  'Secteur énergétique' }, 
    { value: 'Secteur public', label:  'Secteur public' },
    { value: 'Industrie pharmaceutique', label:  'Industrie pharmaceutique' },
    { value: 'Télécommunications', label:  'Télécommunications' },
    { value: 'Assurances', label:  'Assurances' },
    { value: 'Santé', label:  'Santé' },
    { value: 'Industrie automobile', label:  'Industrie automobile' },
    { value: 'BTP', label:  'BTP' },
    { value: 'Industrie alimentaire', label:  'Industrie alimentaire' },
  ]
