import React from 'react'
import Style from './CompletedProfil.module.scss'
import {ImCross} from 'react-icons/im'

const CompletedProfil = () => {
    return (
        <div className={Style.container}>
            <div className={Style.containDetails}>
                <div className={Style.containImg}>
                    <h5 className={Style.title}>Profil complété à <span>75%</span></h5>
                    <div className={Style.barre}></div>
                </div>
               
                <p>Finaliser mon profil en complétant ces informations :</p>
                <p className={Style.red}><ImCross/>  Finaliser mon profil en complétant ces informations :</p>
                <p className={Style.red}><ImCross/>  Finaliser mon profil en complétant ces informations :</p>
            </div>
        </div>
    )
}

export default CompletedProfil
