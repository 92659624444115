import React from 'react'
import Style from './DetailMission.module.scss'


const DetailMission = (data:any) => {

    return (
        <div className={Style.container}>
            <div className={Style.containAll}>
                <div className={Style.containDetails}>
                    <div className={Style.width}>
                        <h1 className={Style.title}><span>Titre </span> {data.mission.title}</h1>
                        <div className={Style.barre}></div>
                        <div className={Style.detailsMission}>
                            <div className={Style.detail}>
                                <h6>Poste proposé </h6>
                                <p>Lorem Ipsum</p>
                            </div>
                            <div className={Style.detail}>
                                <h6>Séniorité du consultant</h6>
                                <p>3 / 5 ans</p>
                            </div>
                            <div className={Style.detail}>
                                <h6>Fréquence par semaine</h6>
                                <p>Temps Plein</p>
                            </div>
                        </div>
                        <div className={Style.detailsMission}>
                            <div className={Style.detail}>
                                <h6>Lieu de travail</h6>
                                {
                                    data.mission.place.map((res:any, i:number)=> (
                                        <p  key={i}>{res.value}</p>
                                    ))
                                }
                            </div>
                            <div className={Style.detail}>
                                <h6>Début de la mission</h6>
                                <p>Des que Possible</p>
                            </div>
                            <div className={Style.detail}>
                                <h6>Durée de la mission</h6>
                                {
                                    data.mission.duration.map((res:any, i:number)=> (
                                        <p key={i}>{res.value}</p>
                                    ))
                                }
                            </div>  
                        </div>
                        <div className={Style.detail}>
                            <h6>Description</h6>
                            <p className={Style.para}>{data.mission.description}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DetailMission
