import React, { useState } from 'react'
import { useLocation } from 'react-router'
import DetailMission from '../../Components/DetailMission/DetailMission'
import Profil from '../../Components/Profil/Profil'
import { find } from '../../Utility/request'
import MyProfil from '../MyProfil/MyProfil'
import Style from './Mission.module.scss'


const Mission = () => {

    const location:any = useLocation()
    const [search, setSearch] = useState({
        search: "",
    });
    const [result, setResult] = useState<any>()
    const [profil, setProfil] = useState({
        boolean: false,
        user: {}
    })

    const show = async () => {
        if(search.search) {
            const data = find(search.search)
            data.then(res => {
                setResult(res)
            })
            .catch(err => {
                console.log(err)
            })
        }
    };

    const handleChange = (e: any) => {
        setSearch({
            ...search,
            [e.target.name]: e.target.value,
        });
    };

    const idMission = location.state.project._id

    return (
        <div className={Style.container}>
            <div className={Style.containMission}>
                {
                    location.state.project ? (
                        <DetailMission mission={location.state.project} />
                    )
                    :
                    (
                        null
                    )
                }
            </div>
            <div className={Style.containInput}>
                <label htmlFor="search">Rechercher :</label>
                <input
                    type="text"
                    id="search"
                    name="search"
                    value={search.search}
                    onChange={handleChange}
                />
                <button onClick={show}>Search</button>
            </div>
            <div className={ !profil.boolean ? Style.result : Style.hidden}>
                {
                    result != null
                        ?
                            <Profil result={result} setProfil={setProfil} id={idMission}/>
                        :
                            <h1 className={Style.red}>Aucun résultat à votre recherche</h1>
                }
            </div>
                {
                    profil.boolean === true ? (
                        <MyProfil data={profil.user} setProfil={setProfil} id={idMission}/>
                    )
                    :
                    (
                        null
                    )
                }
        </div>
    )
}

export default Mission
