import React from 'react'
import Style from './Experiences.module.scss'
import bat from '../../../assets/bat.svg'
import moment from 'moment'

const Experiences = ({user}:any) => {


    return (
        <div className={Style.container}>
            <div className={Style.containDetails}>
                <h1 className={Style.title}>Experiences</h1>
                <div className={Style.barre}></div>
                    { 
                        user.profile.experience.map((res:any, i:number) => (
                            <div className={Style.enterprise} key={i}>
                                <h6 className={Style.title}> <img src={bat} alt="bâtiment"/> {res.company}</h6>
                                <div className={Style.detailEnterprise}>
                                    <h6 className={Style.weight}>Rôle dans l’entreprise</h6>
                                    <h6>{res.title}</h6>
                                    <h6>{moment(res.startDate).format('MM-YYYY')} à { res.endDate ? moment(res.endDate).format('MM-YYYY') : "Aujourd'hui"} </h6>
                                </div>
                                <p>
                                    {res.description}
                                </p>
                                <p className={Style.weight}>#Agile #Scrum #Agile #Scrum #Sprint</p>
                            </div>
                        ))
                    }
            </div>   
        </div>
    )
}

export default Experiences
