import { useEffect } from "react";
import { BrowserRouter, BrowserRouter as Router, Switch, useHistory } from "react-router-dom";
import Nav from "./Components/Navbar/Nav";
import Auth from './Pages/Auth/Connexion/Auth'
import CreateMission from "./Pages/CreateMission/CreateMission";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Mission from "./Pages/Mission/Mission";
import Search from "./Pages/Search/Search";
import AdminRoutes from "./Routes/AdminRoutes";
import PublicRoutes from "./Routes/PublicRoutes";
import { checkUser } from "./Utility/untils";

function App() {

    const user = checkUser()
    const history = useHistory()
    
    useEffect(() => {
        if(user) {
            history.push('/')
        }
    }, [user, history])

    return (

        <Router>
            <BrowserRouter>
                <Nav />
                <Switch>
                    <PublicRoutes exact path="/" component={Auth}/>
                    <AdminRoutes path="/dashboard" component={Dashboard} />
                    <AdminRoutes path="/mission" component={Mission} />
                    <AdminRoutes path="/createMission" component={CreateMission} />
                    <AdminRoutes path="/search" component={Search} />
                </Switch>
            </BrowserRouter>
        </Router>
    );
}

export default App;
