import React from 'react'
import Style from './MyPreferences.module.scss'
// import pencil from '../../../assets/pencil.svg'

const MyPreferences = ({user}:any) => {
    return (
        <div className={Style.container}>
            <div className={Style.containDetails}>
                <h1 className={Style.title}>Préférences de mission</h1>
                <div className={Style.barre}></div>
                <div className={Style.divinput}>
                    <label htmlFor="zone">Zone d’activite</label>
                    <input type="text" name="zone" value="Paris" disabled/>
                    {/* <button><img src={pencil} alt="représente un stylo"/></button> */}
                </div>
                <div className={Style.divinputTJM}>
                    <label htmlFor="tjm">TJM</label>
                    <input type="text" name="tjm" value="450 €" disabled/>
                    {/* <button><img src={pencil} alt="représente un stylo"/></button> */}
                </div>
                <div className={Style.divinputSecteur}>
                    <label htmlFor="secteur">Secteur d’activite </label>
                    <div>
                        <input type="text" name="secteur" value="Organisation entreprise" disabled/>
                        {/* <button><img src={pencil} alt="représente un stylo"/></button> */}
                    </div> 
                </div>
            </div>
        </div>
    )
}

export default MyPreferences
