import jwtDecode from "jwt-decode"

const user = localStorage.getItem('currentUser')
const status = localStorage.getItem('status')

export const checkUser =  () => {
   
    if(user) {
        let token:any = jwtDecode(user)
        let tokenExpire = token.exp
        let expiresIn = ((tokenExpire * 1000) - 60000)
        if(Date.now() >= expiresIn) {
            localStorage.clear()
            window.location.reload()
            return true
        }
        else {return false}
    }
} 

export const isAdmin =  () => {
    if(status) {
        return true
    }
}

export const checkPathRegister = () => {
    const firstPath = window.location.pathname.split('/')[1];
    if(!firstPath) {
        return true
    }
}
