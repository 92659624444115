import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import {isAdmin} from '../Utility/untils'

const PublicRoutes = ({component: Component, ...rest}:any) => {

    return (
        <Route {...rest} render={props => (
            !isAdmin() ?
                <Component {...props} />
            : <Redirect to="/dashboard" />
        )} />
    );
};

export default PublicRoutes;