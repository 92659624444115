import React, { useEffect, useState } from "react";
import Profil from "../../Components/Profil/Profil";
import { projectAll } from "../../Utility/request";
import Style from "./Dashboard.module.scss";

const Dashboard = () => {
    
    const [result, setResult] = useState<any>()

    const getProject = () => {
        const result = projectAll()
        result.then(res => {
            setResult(res)
        })
        .catch(err => {
            console.log(err)
        })
    }

    useEffect(() => (
        getProject()
    ), [])

    return (
        <div className={Style.container}>
          
            <div className={Style.containProject}>
               {
                   result ? (
                       <Profil project={result} />
                   )
                   :
                   (
                       <h1>Aucun Projet actuellement</h1>
                   )
               }
            </div>
            <div className={Style.search}>

            </div>
        </div>
    );
};

export default Dashboard;
