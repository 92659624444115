import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { checkPathRegister } from '../../Utility/untils'
import Style from './Nav.module.scss'


const Nav = () => {

    const history = useHistory()
    const redirectDash = () => {
        history.push('/dashboard')
    }

    const redirectNewMission = () => {
        history.push('/createMission')
    }

    const redirectSearch = () => {
        history.push('/search')
    }

    const [showNav, setShow] = useState(false)

    useEffect(() => {
        if(checkPathRegister()) {
            setShow(true)
        }
    },[setShow])

    return (
        <header className={ showNav ? Style.hidden : Style.header}>
            <nav className={Style.nav}>
                <button onClick={e => {redirectSearch()}}>Search</button>
                <button onClick={e => {redirectDash()}}>Projet</button>
                <button onClick={e => {redirectNewMission()}}>Créer un projet</button>
            </nav>
        </header>
    )
}

export default Nav
