import React from 'react'
import Style from './Available.module.scss'
import Profil from '../../../assets/profil.svg'
import moment from 'moment'

const Available = ({user}:any) => {
      
    return (
        <div className={Style.container}>
            <div className={Style.containDetails}>
                <div className={Style.containImg}>
                    <img src={Profil} alt="profil de la personne"/>
                    <h5 className={Style.name}>{user.firstname ? user.firstname : null} {user.lastname ?  user.lastname : null}</h5>
                    <p className={Style.job}>{user.company ? user.company.companyName : null}</p>
                    <p>{user.email ? user.email :null}</p>
                    <p>{user.address ? user.address.mobilephone : null}</p>
                    <p>{user.address ? user.address.address1 : null}</p>
                    <p>{user.address ? user.address.city : null}</p>
                    <div className={Style.barre}></div>
                    <a href={user.profile.resume && user.profile.resume.resumeUrl  ? user.profile.resume.resumeUrl : null} className={Style.href}>CV : {user.profile.resume && user.profile.resume.filename ? user.profile.resume.filename : null}</a>
                    <p>Dispo : {user.profile.isAvailable ? "oui" : "non" }</p>
                    <p>Date de dispo : {user.profile.availableDate ? moment(user.profile.availableDate).subtract(10, 'days').calendar() : null}</p>
                </div>
            </div>
        </div>
    )
}

export default Available
