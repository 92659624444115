import axiosConfig from './axiosConfig'
import * as dotenv from 'dotenv'

dotenv.config()
let url = process.env.REACT_APP_API_URL

export const logintest = async (user:object) => {
    const result = await axiosConfig.post(url + 'api/v1/auth/authenticate', user)
    return result.data
}

export const find = async (search: string) => {
    const result = await axiosConfig.get(url + 'api/v1/user/find/' + search)
    return result.data
}

export const projectAll = async () => {
    const result = await axiosConfig.get(url + 'api/v1/project')
    return result.data
}

export const assign = async (id:string, user:object) => {
    const result = await axiosConfig.post(url + 'api/v1/project/' + id + '/propositions', user)
    return result.data
}

export const hidden = async (project:object, config:object) => {
    const result = await axiosConfig.post(url + 'api/v1/project', project, config)
    return result.data
}